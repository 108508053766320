import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/code/src/components/layout.js";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const HugeBox = makeShortcode("HugeBox");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Referenzen`}</h2>
    <HugeBox mdxType="HugeBox">
      <ul>
        <li parentName="ul"><a parentName="li" {...{
            "href": "/referenzen#florida",
            "title": "Erfahren Sie mehr über diese Referenz"
          }}>{`Florida-Eis Manufaktur GmbH`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "/referenzen#jobberbee",
            "title": "Erfahren Sie mehr über diese Referenz"
          }}>{`JobberBee AG`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "/referenzen#gema",
            "title": "Erfahren Sie mehr über diese Referenz"
          }}>{`Gema`}</a></li>
      </ul>
      <p><a parentName="p" {...{
          "href": "/referenzen",
          "title": "Zur Übersicht der Referenzen"
        }}>{`alle anzeigen`}</a></p>
    </HugeBox>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      